<template>
    <section class="bg-login">
        <div class="row justify-content-center w-100">
            <div class="col-10 col-sm-8 col-md-7 col-lg-5 col-xl-5 bg-white br-5  py-5 text-center">
                <div v-if="seleccionarConjuntoSection">
                    <img src="/img/generales/logoOscuroMCD.svg" alt="" class="img-logo" />
                    <p class="text-general f-600 f-22 my-4">Bienvenido</p>
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-8 col-md-8">
                            <p class="f-15 mb-3">Seleccione el conjunto al que pertenece</p>
                            <select-conjuntos @setConjunto="obtenerConjunto" />
                            <button class="btn btn-general f-300 f-14 btn-block mt-4 py-2" @click="irIngresarCredenciales">
                                Ingresar
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="ingresarCredenciales">
                    <div class="row">
                        <i class="col-2 cr-pointer icon-back f-20" @click="resetLogin" />
                    </div>
                    <img v-if="conjunto.logo" :src="conjunto.logo" alt="" class="img-logo-conjunto obj-cover" width="350" height="255" />
                    <p class="f-15 mt-3 mb-4 f-600">{{ conjunto.nombre }}</p>
                    <p class="text-general f-600 f-22 mb-4">Bienvenido administrador</p>
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-8 col-md-8">
                            <el-input v-model="usuario" placeholder="Correo electrónico o Celular" class="w-100 mb-3"> 
                                <i slot="suffix" class="el-input__icon icon-account-outline" />
                            </el-input>
                            <el-input v-model="contrasena" placeholder="Contraseña" class="w-100 mb-2" show-password @keyup.enter="login" />
                            <p class="text-right f-13 cr-pointer" @click="recuperarContrasena">Recuperar contraseña</p>
                            <button v-loading="cargando" class="btn btn-general f-300 f-14 btn-block mt-4 py-2" :style="`${cargando ? 'pointer-events:none;' : ''}`" @click="login">
                                Ingresar
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="recuperarContrasenaSection">
                    <div class="row">
                        <i class="col-2 cr-pointer icon-back f-20" @click="recuperarContrasena" />
                    </div>
                    <p class="f-22 mt-3 mb-5 f-600">Recuperación de contraseña</p>
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-8 col-md-8">
                            <p class="f-17">Ingresa el número de teléfono asociado a tu cuenta.</p>
                            <p class="f-17 mb-4">Te enviaremos un código de verificación.</p>
                            <el-input v-model="inputCorreoRecuperacion" placeholder="Numero de celular" class="w-100 mb-3" /> 
                            <button class="btn btn-general f-300 f-14 btn-block mt-3 py-2" @click="irIngresarCodigo">
                                Enviar
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="ingresarCodigoSection">
                    <div class="row">
                        <i class="col-2 cr-pointer icon-back f-20" @click="irIngresarCodigo" />
                    </div>
                    <p class="f-22 mt-3 mb-5 f-600">Ingresar código de verificación</p>
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-8 col-md-8">
                            <p class="f-17 mb-4">Ingresa el código de 4 dígitos que enviamos al {{ inputCorreoRecuperacion }}</p>
                            <el-input v-model="inputCodigo1" placeholder="*" class="mb-3 mr-2 input-codigo" />
                            <el-input v-model="inputCodigo2" placeholder="*" class="mb-3 mr-2 input-codigo" />
                            <el-input v-model="inputCodigo3" placeholder="*" class="mb-3 mr-2 input-codigo" />
                            <el-input v-model="inputCodigo4" placeholder="*" class="mb-3 mr-2 input-codigo" />
                            <p class="f-17 mb-4">
                                ¿No has recibido nada?
                                <span class="f-17 f-600 cr-pointer" @click="reenviarCodigo">Reenviar código</span>
                            </p>
                            <button class="btn btn-general f-300 f-14 btn-block mt-3 py-2" @click="actualizarContrasena">
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="ingresarNuevaContrasena">
                    <p class="f-22 mb-4">Ingresa una nueva contraseña</p>
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-8 col-md-8">
                            <ValidationProvider v-slot="{errors}" rules="required|min:6|max:20" name="contraseña">
                                <el-input v-model="inputNuevoPassword" placeholder="Contraseña" class="w-100 mb-2" show-password show-word-limit maxlength="20" />
                                <span class="text-danger f-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                            <ValidationProvider v-slot="{errors}" rules="confirmed:contraseña|required||min:6|max:20" name="repetir contraseña">
                                <el-input v-model="inputNuevoRepetirPassword" placeholder="Contraseña" class="w-100 mb-2" show-password show-word-limit maxlength="20" />
                                <span class="text-danger f-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                            <button class="btn btn-general f-300 f-14 btn-block mt-4 py-2" @click="guardarIngresarSistema">
                                Guardar e ingresar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Conjuntos from '~/services/conjuntos'
import Login from '~/services/login'
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
    layout: 'seleccionarConjunto',
    data(){
        return{
            cargando: false,
            idConjunto: null,
            seleccionarConjuntoSection: true,
            ingresarCredenciales: false,
            recuperarContrasenaSection: false,
            ingresarCodigoSection: false,
            ingresarNuevaContrasena: false,
            value: '',
            usuario: '',
            contrasena: '',
            inputCorreoRecuperacion: '',
            inputRecuperarContrasena: '',
            inputCodigo1: '',
            inputCodigo2: '',
            inputCodigo3: '',
            inputCodigo4: '',
            inputNuevoPassword: '',
            inputNuevoRepetirPassword: '',
        }
    },
    computed: {
        ...mapGetters({
            conjunto: 'conjunto/conjunto',
        }),
    },
    created(){
        this.resetLogin()
        this.listar()
        if(this.conjunto){
            this.irIngresarCredenciales()
        }
    },
    methods:{
        irIngresarCredenciales(){
            if(this.idConjunto == null) return this.notificacion('','Por favor selecciona un conjunto')
            this.seleccionarConjuntoSection = !this.seleccionarConjuntoSection
            this.ingresarCredenciales = !this.ingresarCredenciales
        },
        recuperarContrasena(){
            this.ingresarCredenciales = !this.ingresarCredenciales
            this.recuperarContrasenaSection = !this.recuperarContrasenaSection
        },
        async irIngresarCodigo(){
            try {
                if (this.inputCorreoRecuperacion == '' || this.inputCorreoRecuperacion == null)return;

                let payload = {
                    usuario : this.inputCorreoRecuperacion,
                    id_conjunto : this.conjunto.id,
                }

                const {data} = await Login.recuperarContraseña(payload)

                if (data.success){
                    this.recuperarContrasenaSection = !this.recuperarContrasenaSection
                    this.ingresarCodigoSection = !this.ingresarCodigoSection
                }

            } catch (e){
                this.errorCatch(e)
            }
        },
        ingresarSistema(){
            this.$router.push({ name: 'login.entrando' }, 5000)
        },
        async reenviarCodigo(){
            try {
                let payload = {
                    usuario : this.inputCorreoRecuperacion,
                    id_conjunto : this.conjunto.id,
                }

                const {data} = await Login.reenviarCodigo(payload);

                if (data.success){
                    this.$notify({
                        title: 'Codigo reenviado',
                        message: '',
                        type: 'success'
                    });
                }
            } catch (error){
                this.errorCatch(e);
            }
        },
        async actualizarContrasena(){
            try {
                if (this.inputCorreoRecuperacion == '' || this.inputCorreoRecuperacion == null)return;

                let payload = {
                    usuario : this.inputCorreoRecuperacion,
                    id_conjunto : this.conjunto.id,
                    codigo : this.inputCodigo1+this.inputCodigo2+this.inputCodigo3+this.inputCodigo4,
                }

                const {data} = await Login.verificarCodigo(payload)

                if (data.success){
                    this.ingresarCodigoSection = !this.ingresarCodigoSection
                    this.ingresarNuevaContrasena = !this.ingresarNuevaContrasena
                }

            } catch (e){
                this.errorCatch(e)
            }
        },
        async guardarIngresarSistema(){
            try {
                if (this.inputNuevoPassword != this.inputNuevoRepetirPassword){
                    return this.$notify({
                        title: 'Erro contraseña',
                        message: 'La contraseña no coincide',
                        type: 'warning'
                    });
                } else if (this.inputNuevoPassword == ''){
                    return this.$notify({ title: 'Erro contraseña', message: 'El campo contraseña es obligatorio', type: 'warning' });
                }

                this.cargando = true;

                const payload = {
                    'usuario' : this.inputCorreoRecuperacion,
                    'id_conjunto' : this.conjunto.id,
                    'password' : this.inputNuevoPassword,
                    'password_confirmation' : this.inputNuevoRepetirPassword,
                    'app' : 'admin',
                }
                const { data } = await Login.resetPassword(payload)

                // Save the token.
                this.$store.dispatch('auth/saveToken', {
                    token: data.data.token,
                    remember: false
                })

                // Fetch the user.
                await this.$store.dispatch('auth/fetchUser')

                this.$notify({
                    title: 'Login Exitoso',
                    message: data.mensaje,
                    type: 'success'
                });
                // Redirect home.
                this.$router.push({ name: 'login.entrando' }, 5000)

            } catch (error){
                this.$notify({
                    title: 'Login Fallido',
                    message: "Verifique los datos de autenticación",
                    type: 'warning'
                });

                this.errorCatch(e)
            } finally{
                this.cargando = false
            }
        },
        resetLogin(){
            this.idConjunto = null
            this.ingresarCredenciales = false
            this.seleccionarConjuntoSection = true
            localStorage.removeItem('conjunto_modulos');
        },
        async listar(){
            try {
                const {data} = await Conjuntos.listarConjuntos()
                this.conjuntos = data.data.data
            } catch (e){
                this.errorCatch(e);
            }
        },
        async obtenerConjunto(conjunto){
            this.idConjunto = conjunto;
            await this.$store.dispatch('conjunto/obtener',conjunto)
            await this.getModulos();
        },
        // LOGIN
        async login(){
            try {

                if(this.idConjunto == null) return this.notificacion('','Por favor selecciona un conjunto antes de iniciar sesión')
                if(!this.usuario || !this.contrasena) return this.notificacion('','Por favor escribe un usuario o contraseña validos')

                this.cargando = true;
                // Submit the form.
                const payload = {
                    'usuario' : this.usuario,
                    'contrasena' : this.contrasena,
                    'id_conjunto' : this.conjunto.id,
                    'app' : 'admin',
                }
                const { data } = await axios.post('/auth/login-mobile',payload)

                // Save the token.
                this.$store.dispatch('auth/saveToken', {
                    token: data.data.token,
                    remember: false
                })

                // Fetch the user.
                await this.$store.dispatch('auth/fetchUser')

                this.$notify({
                    title: 'Login Exitoso',
                    message: data.mensaje,
                    type: 'success'
                });
                // Redirect home.
                this.$router.push({ name: 'login.entrando' }, 5000)
            } catch (e){
                this.$notify({
                    title: 'Login Fallido',
                    message: "Verifique los datos de autenticación",
                    type: 'warning'
                });
            } finally{
                this.cargando = false
            }
        },

        async getModulos(){
            try {
                const params = {
                    idConjunto: this.idConjunto
                };
                const {data} = await Conjuntos.getModulos(params);
                localStorage.setItem('conjunto_modulos', JSON.stringify(data));
            } catch(e){
                this.errorCatch(e);
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.bg-login{
    background-image: url('/img/generales/fondoMCD.png');
    background-size: cover;
    background-position-x: right;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .img-logo{
        width: 163px;
        height: 156px;
    }
    .input-codigo{
        width: calc((100% / 4) - 8px);
        .el-input__inner{
            text-align: center !important;
        }
    }
}
</style>