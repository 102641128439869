import axios from 'axios'

const API = '/auth'

const Login = {
    recuperarContraseña(payload){
        return axios.post(`${API}/recuperar-contrasena/admin`,payload)
    },
    reenviarCodigo(payload){
        return axios.post(`${API}/reenviar-codigo`,payload)
    },
    verificarCodigo(payload){
        return axios.post(`${API}/verificar-codigo`,payload)
    },
    resetPassword(payload){
        return axios.post(`${API}/resetear-contrasena`,payload)
    },
}

export default Login
